const Header = () => {
  return (
    <header>
      <nav class="navbar navbar-dark bg-primary shadow">
        <div class="container">
          <span class="navbar-brand mb-0 h1 fw-bold fs-2">DesenrolaQI</span>
        </div>
      </nav>
    </header>
  );
};

export default Header;
