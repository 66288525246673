import { https } from "./api";

export default class {

    constructor() {
        this.instance = https('people');
    }  

    getData = (hash) => {
        return this.instance( {
            method: 'GET',
            url: `/${hash}`
        })
    }



}