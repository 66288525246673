import axios from 'axios' ;


export const https = (baseURL = '') => {
  const instance = axios.create({
    baseURL: `https://api-acordoedu.qi.edu.br/${baseURL}`,
    // baseURL: `http://localhost:9095/${baseURL}`,
    headers: {
    //   'x-access-token': getLoginDataFromStorage().token,
    },
  })
  return instance
}
