import Footer from "../Components/Footer";
import Header from "../Components/Header";

const NoDebt = () => {
    return (
        <>

            <Header />

            <main className="container mb-auto mt-5">

                <p className="text-center h3">
                Você não possui quaisquer dívidas elegíveis para negociação na 
                <span className="fw-bold text-nowrap"> AcordoEdu</span>, em parceria com a 
                <span className="fw-bold text-nowrap"> QI Faculdade & Escola Técnica</span>.

                </p>
            </main>

            <Footer />

        </>
    );
}

export default NoDebt;