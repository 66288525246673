import DebtService from "../Services/debt";
import CardDeck from "../Components/CardDeck";
import DebtAgreementOptionCard from "../Components/DebtAgreementOptionCard";
import {  formatMoney } from "../Utils";
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";


import Loading from "./Loading";
import PersonContext from "../Contexts/Person";
import ModalConsent from "../Components/ModalConsent";
import AgreementService from "../Services/agreement";
import AgreementModal from "../Components/AgreementModal";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Flame from "../Assets/flame.svg";


const DebtAgreement = () => {

  
  const { hash } = useParams();
  const [debt, setDebt] = useState({});
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isConsentModalOpen, setIsConsentModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
const [person, setPerson]= useState();
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);
  const [paymentLink, setPaymentLink] = useState(null);
  
  const processAgreement = () => {

    const service = new AgreementService();

    setIsLoading(true);
    service.getCheckoutUrl(hash, selectedOption)
    .then((response) => {
      setPaymentLink(response.data.url);
      setIsAgreementModalOpen(true);
    }).finally(() => {
      setIsLoading(false);
    })  

    

  }

  useEffect(() => {
    setIsLoading(true);

    const service = new DebtService();
    service.getData(hash).then((response) => {
      setDebt(response.data);
      setPerson(response.data.person);
      setError(false);
    })
      .catch((e) => {
        setError(true);
      }).finally(() => {
        setIsLoading(false);
      });

  }, []);

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return <h3 className="text-center text-danger mt-5">Houve um erro ao consultar seu CPF. Tente novamente mais tarde.</h3>;
  }

  if (Array.isArray(debt) && debt.length == 0) {
    return (
      <h3 className="text-center mt-5">
        Não encontramos nenhuma pendência para este CPF
      </h3>
    );
  }


  return (
    <>
      <AgreementModal
      
      isOpen={isAgreementModalOpen}
      setIsOpen={setIsAgreementModalOpen}
      next={paymentLink}

      />
      <ModalConsent
      isOpen={isConsentModalOpen} 
      setIsOpen={setIsConsentModalOpen}
      next={processAgreement}

       />

<Header/>
      <main className="container mb-auto">
        <section className="row mt-3 rounded p-2">

          <div className="col-12 col-md-6">
            <h2 className="fw-bold">
              <span className="text-nowrap">Valor original:</span>
              <span className="text-danger text-nowrap  ms-2">
                {formatMoney(debt.original)}
              </span>
            </h2>
          </div>
          <div className="col-12 col-md-6 text-start text-md-end">
            <h2 className="d-inline fw-bold text-nowrap">Valor proposto:</h2>
            <h2 className="text-success d-inline fw-bold">
              <span className="text-nowrap ms-2">
                {formatMoney(debt.suggested)}
              </span>
              <p className="fw-normal fs-4 text-nowrap">
                <img  src={Flame} className="me-1"/><span className="fw-bold">{debt.discount * 100}%</span> de desconto
              </p>
            </h2>
          </div>
        </section>

        <section className="mt-auto">
          <CardDeck>
            {debt.options
              ? debt.options.map((e, index) => (
                <DebtAgreementOptionCard
                  title={e.title}
                  description={e.description}
                  number_of_installments={e.number_of_installments}
                  amount_each_installment={e.amount_each_installment}
                  first_payment_date={e.first_payment_date}
             
                  id={index}
                  onChoose={() => {setIsConsentModalOpen(true); setSelectedOption(index)}}
                />
              ))
              : ""}

    
          </CardDeck>
        </section>
      </main>

      <Footer/>
    </>
  );
}

export default DebtAgreement;