import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PersonService from "../Services/person";
import DebtServices from "../Services/debt";
import { cpfMask, formatDate, formatMoney } from "../Utils";

import { useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";

import { useLoading } from "../Contexts/Loading";
import Loading from "./Loading";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import NoDebt from "./NoDebt";

const DebtDetails = () => {
  const { hash } = useParams();
  const navigate = useNavigate();

  const redirectToAgreement = () => {
    navigate(`/acordo/${hash}`);
  };

  const [person, setPerson] = useState(null);

  const [debts, setDebts] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState(null);

  const [isPersonLoading, setIsPersonLoading] = useState(true);
  const [isDebtsLoading, setIsDebtsLoading] = useState(true);

  const [hasDebts, setHasDebts] = useState(false);

  useEffect(() => {
    const personService = new PersonService();
    const debtService = new DebtServices();

    setIsLoading(true);
    setIsPersonLoading(true);
    personService.getData(hash).then((response) => {
      setPerson(response.data.person);
      setIsPersonLoading(false);
    });

    setIsDebtsLoading(true);
    debtService.getDebtDetails(hash).then((response) => {
      setDebts(response.data.debts);
      setIsDebtsLoading(false);
    });
  }, [hash]);

  useEffect(() => {
    if (!isPersonLoading && !isDebtsLoading) {
      setIsLoading(false);
      setHasDebts(true);

      if (!debts || !person) {
        setIsLoading(false);

        setHasDebts(false);

        setMessage(
          "Você não possui quaisquer dívidas elegíveis para negociação na AcordoEdu, em parceria com a QI Faculdade e Escola Técnica."
        );
      }

      if ((Array.isArray(debts) && debts.length == 0) || !person) {
        setIsLoading(false);
        setHasDebts(false);

        setMessage(
          "Você não possui quaisquer dívidas elegíveis para negociação na AcordoEdu, em parceria com a QI Faculdade e Escola Técnica."
        );
      }
    }
  }, [isDebtsLoading, isPersonLoading]);

  if (isLoading) {
    return <Loading />;
  }

  if (!hasDebts) {
    return <NoDebt />
  }



  if (message) {
    return <h3 className="text-center mt-5">{message}</h3>;
  }

  return (
    <>
      <Header />
      <main className="container mb-auto">
        <header className="mt-4 rounded-3 p-3 shadow bg-primary text-white">
          <h2 className="fw-bold">{person.name}</h2>
          <h3>{cpfMask(person.cpf)}</h3>

        
        </header>

        <section className="mt-3 w-100 d-flex flex-row justify-content-between flex-wrap">
          <div className="d-flex justify-content-center align-items-center">
            <h5 className="d-inline mb-0 text-nowrap">Valor total:</h5>
            <p className="d-inline ms-2 mb-0 text-danger">
              {formatMoney(
                debts.reduce(
                  (partialSum, a) => partialSum + parseFloat(a.amount),
                  0
                )
              )}
            </p>
          </div>

          <button
            className="btn btn-success py-2 ms-auto"
            href="#"
            onClick={redirectToAgreement}
            id="buscar-ofertas-negociacao"
          >
            <span>
              Buscar minhas ofertas de negociação
            </span>
          </button>

          


        </section>

        <section className="accordion mt-3" id="debts-accordion">
          {debts.map((e, index) => {
            return (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id={`accordion-item-${index}`}>
                  <button
                    className="accordion-button text-dark"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse-${index}`}
                    aria-controls={`collapse-${index}`}
                  >
                    {e.service} - {formatDate(e.due_date)}
                  </button>
                </h2>
                <div
                  id={`collapse-${index}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`accordion-item-${index}`}
                  data-bs-parent="#debts-accordion"
                >
                  <div class="accordion-body row">
                    <div className="col">
                      <h6 className="fw-bold ">Produto</h6>
                      <p>{e.product}</p>
                    </div>

                    <div className="col">
                      <h6 className="fw-bold ">Valor Total</h6>
                      <p>{formatMoney(e.amount)}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </section>


      </main>
      <Footer />
    </>
  );
};

export default DebtDetails;
