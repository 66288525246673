import { https } from "./api";

export default class {

    constructor() {
        this.instance = https('agreements');
    }  

    getCheckoutUrl = (hash, option) => {
        return this.instance( {
            method: 'POST',
            url: `/${hash}/${option}`
        })
    }



}