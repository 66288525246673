import { https } from "./api";

export default class {

    constructor() {
        this.instance = https('debts');
    }  

    getData = (hash) => {
        return this.instance({
            method: 'GET',
            url: `/${hash}`
        });
    }

    getDebtDetails = (hash) => {
        return this.instance({
            method: 'GET',
            url: `/details/${hash}`
        });
    }

    getAccessHash = (cpf) => {
        return this.instance({
            method: 'POST',
            url: '/requestAccess',
            data: {
                cpf
            }
        });
    }

}