import { useParams } from "react-router-dom";
import { useContext } from "react";
import { useNavigate } from "react-router-dom/dist";
import DebtService from "../Services/debt";
import Pessoa from "../Assets/qi/pessoa.png";
import { useState } from "react";
import { cpfMask } from "../Utils";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

const DebtConsult = () => {
  const navigate = useNavigate();

  const [cpf, setCpf] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const service = new DebtService();

    setIsLoading(true);
    service.getAccessHash(cpf).then((response) => {
      setIsLoading(false);
      navigate(`/consulta/${response.data.hash}`);
    });
  };

  return (
    <>
     <Header/>
      <main class="container ">
        <section class="row">
          <aside class="col-12 col-md-6">
            <div class="w-75 mt-3">
              <h2 class="display-5">
                <span class="text-nowrap fw-bold">Negociação de Dívidas</span>
                <br />
                <span class="text-nowrap fw-bold">com até 99% de desconto</span>
              </h2>

              <p class="fs-5">
                <span class="text-nowrap">Renegocie suas dívidas com a</span>
                <br />
                <span class="text-nowrap">QI Faculdade & Escola Técnica</span>
              </p>
            </div>

            <form onSubmit={handleSubmit}>
              <input
                type="text"
                class="form-control fs-4 text-center p-2 shadow-none border-none"
                placeholder="Digite aqui seu CPF"
                required
                inputmode="numeric"
                value={cpf}
                onChange={(e) => setCpf(cpfMask(e.target.value))}
                disabled={isLoading}
              />

              <button
                type="submit"
                class="form-control p-2 fs-4 btn btn-success mt-3"
                disabled={isLoading}
                id="buscar-cpf"
              >
                {isLoading ? (
                  <>
                    <span
                      class="spinner-border spinner-border-sm me-2 mb-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Buscando
                  </>
                ) : (
                  "Consultar CPF"
                )}
              </button>
            </form>
          </aside>

          <aside class="col-12 col-md-6">
            <img src={Pessoa} class="w-100" />
          </aside>
        </section>
      </main>
      <Footer/>
    </>
  );
};

export default DebtConsult;
