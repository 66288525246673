import { formatDate, formatMoney } from "../Utils";

const DebtAgreementOptionCard = (props) => {

    const { 
        title, 
        description, 
        number_of_installments, 
        amount_each_installment, 
        first_payment_date ,

        id,
        onChoose
    } = props;

    

    return (
        <div className="col" key={id}>
            <div className="card h-100 ">
                <div className="card-body text-center rounded-3 bg-primary text-white">
                    <h5 className="card-title fw-bold  fs-3">{title}</h5>
                    <p className="card-text ">{description}</p>

                    <p className="card-text fs-4 fw-bold">{number_of_installments}x de {formatMoney(amount_each_installment)}</p>
                
                    <p className="card-text">Total { number_of_installments > 1 ? "parcelado" : "à vista" }: {formatMoney(number_of_installments * amount_each_installment)}</p>
            
                    <button onClick={onChoose} className="btn btn-light w-50" id="escolher-acordo">Escolher</button>
                </div>
            </div>
        </div>
    );
}

export default DebtAgreementOptionCard;