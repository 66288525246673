import { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

import Cielo from "../Assets/cielo.jpg"
const AgreementModal = (props) => {
    
    const {isOpen, setIsOpen, next} = props;


  const handleClose = () => setIsOpen(false);
  const handleShow = () => setIsOpen(true);
    return (
        
      <Modal show={isOpen} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Pagamento</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-center">

        <img src={Cielo} className=" rounded img-fluid"/>

            <p className="mt-5">Clique abaixo para prosseguir para pagamento:</p>

            
            <a  className=" text-center btn btn-success" href={next} target="_blank" id="ir-cielo">Prosseguir para CIELO</a>
            
        </Modal.Body>
      </Modal>
    );
}

export default AgreementModal;