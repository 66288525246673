import { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";


const ModalConsent = (props) => {
    
    const {isOpen, setIsOpen, next} = props;


  const handleClose = () => setIsOpen(false);
  const handleShow = () => setIsOpen(true);
    return (
        
      <Modal show={isOpen} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Confirmação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
            Ao confirmar, você será redirecionado a nossa Central de Pagamentos e após a efetivação do acordo você receberá: 
            </p>

            <ul>
                <li>Termo do Acordo efetivado</li>
                <li>Retirada do CPF em restrição nos Órgãos de Proteção ao Crédito <span className="fw-bold">(72h)</span></li>
                <li className="text-success">Bônus do valor pago em novos cursos QI</li>
            </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Voltar
          </Button>
          <Button variant="success" onClick={() => {handleClose(); next() }} id="confirmar-acordo">
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    );
}

export default ModalConsent;