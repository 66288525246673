import { BrowserRouter, Routes, Route } from "react-router-dom";


import HomePage from "./Pages/Home";
import DebtConsult from "./Pages/DebtConsult";
import DebtDetails from "./Pages/DebtDetails";
import DebtAgreement from "./Pages/DebtAgreement";
import { LoadingProvider } from "./Contexts/Loading";

const App = () => {
  return (
    <LoadingProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/">

            <Route index element={<DebtConsult />} />
            <Route path="*" element={<DebtConsult />} />

            <Route path="consulta" element={<DebtConsult/>}/>
            <Route path="consulta/:hash" element={<DebtDetails/>}/>
            <Route path="acordo/:hash" element={<DebtAgreement/>}/>

          </Route>
          
          <Route path="/:institutionShortName/" >

            <Route index element={<DebtConsult />} />
            <Route path="*" element={<DebtConsult />} />

            <Route path="consulta/:hash" element={<DebtDetails/>}/>
            <Route path="acordo/:hash" element={<DebtAgreement/>}/>
            

          </Route>

        </Routes>
      </BrowserRouter>
    </LoadingProvider>
  );
}

export default App;