import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Logo from "../Assets/qi/logo.png";
const Loading = (props) => {
  
    const {message} = props;
  
    return (
    <>
  
      <Header />
      <section id="loader">
        <div class="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
          <img src={Logo} width="240" alt="Logo QI" />
          <p class="fs-3 text-center">{message ? message : "Carregando..."}</p>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Loading;
